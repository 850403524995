(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Dependencies:
     *
     *    - icon
     */
    Vue.component("follow-modal", {
        props: [
            "isModalOpen", // boolean
            "userDTOInput", // userDTO
            "i18n",
        ],
        data: function () {
            return {
                userDTO: {},
            };
        },
        template: `
<div v-if="isModalOpen"
   class="follow-modal--mask"
   :aria-labelledby="i18n.txtFollowModalTitle"
   aria-modal="true"
   ref="followModal"
   v-cloak>
  <div class="follow-modal__wrapper">
    <div class="follow-modal__content" ref="followModalContent">
      <div class="follow-modal__header">
        <div class="follow-modal__action-title">
          <div class="follow-modal__success">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/check_green.svg">
          </div>
          <h2>{{ i18n.txtFollowModalTitle }}</h2>
        </div>
        <div class="follow-modal__close">
          <button type="button" class="follow-modal__close-button" aria-label="Close" @click="close">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
          </button>
        </div>
      </div>
      <div class="follow-modal__body">
        <h4>{{ txtDiscover }}</h4>
        <div class="follow-modal__user-follow">
          
          <div class="user-card">
            <a href="/wikiloc/org.do" v-if="userDTO.isOrg">
              <img class="user-card__org-badge" src="https://sc.wklcdn.com/wikiloc/images/badge-org.svg" alt="ORG">
            </a>
            <div class="user-card__info">
              <div class="user-card__avatar">
                <a :href="userURL" :title="userDTO.name" class="user-image">
                  <img :src="userPhoto" width="50" height="50" :alt="userDTO.name" :title="txtPhotoOf"/>
                </a>
              </div>
              <div class="user-card__name">
                  <a :href="userURL" class="fn">{{ userDTO.name }}</a>
              </div>
            </div>
            <div class="user-card__stats">
              <div class="user-card__stat">
                <span class="user-card__stat-userrank" title="UserRank">{{ userDTO.userrank }}</span>
              </div>
              <div class="user-card__stat">
                <span class="user-card__stat-icon utag blank icon-trail" title="general.user.tracks-shared">{{ userDTO.nptracks }}</span>
              </div>
              <div class="user-card__stat">
                <span class="user-card__stat-icon utag blank icon-waypoint" title="general.user.waypoints-shared">{{ userDTO.npwaypoints }}</span>
              </div>
            </div>
          </div>
          <button class="follow-modal__button btn btn-lg btn-success" @click="onClickFollow">
            {{ i18n.txtFollowModalFollowButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`,
        methods: {
            onClickFollow: onClickFollow,
            onClickOutside: onClickOutside,
            follow: follow,
            close: close,
        },
        computed: {
            userURL: userURL,
            userPhoto: userPhoto,
            txtDiscover: txtDiscover,
            txtPhotoOf: txtPhotoOf,
        },
        watch: {
            isModalOpen: isModalOpen,
            userDTOInput: userDTOInput,
        },
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */
    const CLOSE_MODAL_TTL_MS = 250; // 0.25ms
    const DEFAULT_PHOTO =
        "https://sc.wklcdn.com/wikiloc/images/noface50x50.png";

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function onClickFollow() {
        this.follow();
    }

    function close() {
        _emitCloseModal(this, true, false);
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();

        const el = this.$refs.followModalContent;
        if (el && !el.contains(event.target)) {
            _emitCloseModal(this, true, false);
        }
    }

    function follow() {
        let url = window.location.origin + "/wikiloc/follow.do";

        let params = "";
        params += "uid=" + this.userDTO.id;
        params += "&fol=" + "true"; // isStartFollowing

        _ajaxPost(url, params, (data) => {
            // no response treatment, no msg shown at error

            setTimeout(() => {
                _emitCloseModal(this, true, CLOSE_MODAL_TTL_MS);
            });
        });
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */
    function userURL() {
        return (
            window.location.origin + "/wikiloc/user.do?id=" + this.userDTO.id
        );
    }

    function userPhoto() {
        return this.userDTO.photo ? this.userDTO.photo : DEFAULT_PHOTO;
    }

    function txtDiscover() {
        return this.userDTO.name
            ? GLOBAL_METHODS.translate("txtFollowModalDiscover", {
                  0: this.userDTO.name,
              })
            : "";
    }

    function txtPhotoOf() {
        return this.i18n.txtFollowModalPhotoOf
            ? this.i18n.txtFollowModalPhotoOf + " " + this.userDTO.name
            : this.userDTO.name;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */
    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        // doubleRaf required to sync events
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
            } else {
                _rmOnClickOutsideEvent(this);
                _enableDocumentScroll(true);
            }
        });
    }

    function userDTOInput(newUserDTO, oldUserDTO) {
        // doubleRaf required to sync events
        _doubleRaf(() => {
            if (newUserDTO) {
                this.userDTO = _factoryUserDTO(newUserDTO);
            }
        });
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */
    function mounted() {
        //1 - Native event Listeners
        _doubleRaf(() => {
            if (
                this.userDTOInput &&
                Object.keys(this.userDTOInput).length > 0
            ) {
                this.userDTO = _factoryUserDTO(this.userDTOInput);
            }
        });
    }

    function beforeDestroy() {
        //1 - Native event Listeners
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    function _emitCloseModal(vueInstance, closeAddToFav, isDelay) {
        let payload = {};
        if (closeAddToFav) {
            payload.closeAddToFav = true;
        }

        if (isDelay) {
            setTimeout(() => {
                vueInstance.$emit("closeFollowModalEvent", payload);
            }, CLOSE_MODAL_TTL_MS);
        } else {
            vueInstance.$emit("closeFollowModalEvent", payload);
        }
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.followModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _rmOnClickOutsideEvent(vueInstance) {
        if (vueInstance.$refs.followModal) {
            vueInstance.$refs.followModal.removeEventListener(
                "click",
                vueInstance.onClickOutside
            );
        }
    }

    /**
     *    Post Async ajax call
     */
    function _ajaxPost(url, formData, callback) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                try {
                    var data = JSON.parse(xmlhttp.responseText);
                } catch (err) {
                    console.error(err.message + " in " + xmlhttp.responseText);
                    return;
                }
                callback(data);
            }
        };

        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8"
        );
        xmlhttp.send(formData);
    }

    function _factoryFavoriteList(item) {
        return new FavoriteList(
            item.id,
            item.name,
            item.isPublic,
            item.favorite,
            item.count,
            item.limitTrails
        );
    }

    function _factoryFavoriteListFromCreateListResponse(response) {
        return new FavoriteList(
            response.id,
            response.name,
            !response.isPrivate
        );
    }

    function _factoryUserDTO(userDTOInput) {
        return new UserDTO(
            userDTOInput.id,
            userDTOInput.name,
            userDTOInput.photo,
            userDTOInput.userrank,
            userDTOInput.nptracks,
            userDTOInput.npwaypoints,
            userDTOInput.isOrg
        );
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
    function UserDTO(id, name, photo, userrank, nptracks, npwaypoints, isOrg) {
        this.id = id;
        this.name = name;
        this.photo = photo;
        this.userrank = userrank ? userrank : 1;
        this.nptracks = nptracks ? nptracks : 0;
        this.npwaypoints = npwaypoints ? npwaypoints : 0;
        this.isOrg = isOrg && (isOrg == true || isOrg == "true") ? true : false;
    }
})();
